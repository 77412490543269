.homepage-container {
  max-width: 1300px;
  margin: 0 auto;
}

.sizes-wrapper {
  display: flex;
}

.CarouselImage {
  width: 100%;
  height: 80vh;
  object-fit: contain;
}

.customModal {
  width: 100%;
  border-radius: 5px;
}

.couponContainer {
  width: 100%;
  padding: 20px;
  border: 1px solid #dfdfdf;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.leftContainer {
  width: 40%;
  margin-left: 10px;
}

.couponIcon {
  height: 20px;
  width: 20px;
}

.couponCodeContainer {
  padding: 4px 10px;
  border: 1px dashed black;
  height: 30px;
  width: max-content;
}

.rightContainer {
  width: 60%;
  margin-left: 100px;
  text-align: justify;
}

@media screen and (max-width: 600px) {
  .customModal {
    margin: 0;
  }

  .couponContainer {
    padding: 20px;
    display: block;
  }

  .leftContainer {
    width: auto;
    margin-left: 0;
  }

  .rightContainer {
    width: 100%;
    margin-left: 0;
    margin-top: 20px;
  }
}
