.UjObT {
  position: sticky;
  top: 0px;
  width: 100%;
  z-index: 1000;
}

.etwMDu {
  background-color: white;
  height: 86px;
  position: relative;
  width: 100%;
  z-index: 1000;
  border-bottom: 1px solid rgb(238, 238, 238);
  display: flex;
  flex-wrap: nowrap;
}

.gUZOTl {
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  width: auto;
}

.ZIGuc {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  flex: 1 1 0%;
  position: relative;
  background-color: inherit;
}

.dVsawd {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  top: 0px;
  right: 0px;
  padding: 0px;
  gap: 5px;
}

.jzxMvs {
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  height: 86px;
  width: 178px;
  display: flex;
}

.jdUpJX {
  width: 1px;
  display: flex;
  background-color: rgb(242, 242, 242);
  height: 100%;
}

.gcLVHe {
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  height: 86px;
  display: flex;
  font-size: 16px;
  flex-direction: column;
  cursor: pointer;
}

.gccyuD {
  color: rgb(0, 0, 0);
  font-weight: 800;
  margin-bottom: 6px;
  font-size: 18px;
}

.jWpzvj {
  display: flex;
  flex-direction: row;
}
.bdWwbr {
  font-size: 13px;
  color: rgb(0, 0, 0);
  font-weight: 400;
  clear: both;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  width: auto;
  max-width: 210px;
  text-overflow: ellipsis;
}
.IiwNg {
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
}
.fqbcdJ {
  width: 0px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid rgb(0, 0, 0);
  margin-left: 8px;
}

.fgHDQx {
  width: 100%;
  padding: 0px 12px;
  height: 46px;
  display: flex;
  gap: 8px;
  border: 1px solid rgb(232, 232, 232);
  border-radius: 12px;
  background: rgb(248, 248, 248);
  color: rgb(130, 130, 130);
  box-shadow: none;
}

.hcexgJ {
  width: 20px;
  height: 20px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-family: CustomFont;
  font-size: 20px;
  color: rgb(31, 31, 31);
  margin: auto;
}

.dPbxWD {
  width: 100%;
}

.iJnYYS {
  height: 100%;
  overflow: hidden;
  position: relative;
}

.iJnYYS #animationText-1 {
  animation-delay: 0ms;
}

.iJnYYS #animationText-2 {
  animation-delay: 1500ms;
}

.liSUTa {
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  height: 86px;
  display: flex;
  cursor: pointer;
  transition: background-color 0.5s ease 0s;
}

.bFHCDW {
  font-size: 18px;
  font-weight: 400;
  color: rgb(54, 54, 54);
  font-family: Okra-Regular;
  transition: font-size 0.5s ease 0s;
}

.eOczDn {
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  height: 86px;
  display: flex;
}

.joEvaa {
  background-color: rgb(12, 131, 31);
  border-radius: 8px;
  cursor: pointer;
  height: 52px;
  width: 112px;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
}

.iyUoPU {
  font-size: 28px;
  color: rgb(255, 255, 255);
  font-family: CustomFont;
  margin-right: 8px;
}

.iQAgjV {
  font-size: 14px;
  color: rgb(255, 255, 255);
  font-family: Okra-Bold;
}

@media screen and (max-width: 1020px) {
  .jzxMvs,
  .dVsawd {
    display: none;
  }
  .kGwSCD {
    height: auto;
    border-bottom: 0px;
  }
  .gcLVHe {
    width: 320px;
  }

  .gcLVHe {
    height: 68px;
    align-items: flex-start;
    padding-left: 16px;
  }
  .bdWwbr {
    max-width: calc((((100vw - 16px) - 8px) - 28px) - 85px);
  }

  .ZIGuc {
    order: 3;
    padding: 0px 16px 12px;
  }
  .fgHDQx {
    border-radius: 12px;
  }
  .dVsawd {
    position: absolute;
    margin-left: auto;
  }
  .liSUTa {
    margin-right: 8px;
    height: 68px;
  }
}

@media screen and (min-width: 1260px) {
  .fgHDQx {
    cursor: text;
    border: 1px solid rgba(0, 0, 0, 0.04);
  }
  .liSUTa {
    width: 162px;
  }
  .eOczDn {
    margin-right: 32px;
    margin-left: 12px;
  }
}
