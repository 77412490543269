.slider {
  display: flex;
  flex-direction: row;
}

.slider button {
  border: 0;
  /* color: grey; */
  font-size: 10px;
  margin: 0 8px;
  /* background-color: rgb(40 40 40 / 85%); */
  padding: 20px 10px;
  vertical-align: middle;
  align-self: center;
  border-radius: 10px;
  background: transparent;
}

.slider button i {
  background: transparent;
  font-size: 30px;
  font-weight: 900;
  color: black;
  opacity: 0.8;
}
