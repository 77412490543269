.shippingContainer2 {
  width: 100vw;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* padding-bottom: 80px; */
}

.shippingBox {
  background-color: white;
  width: 35vw;
  height: 100vh;
  box-sizing: border-box;
  /* overflow: hidden; */
}

.shippingHeading {
  text-align: center;
  color: rgba(0, 0, 0, 0.664);
  font: 400 1.3vmax;
  padding: 1.3vmax;
  border-bottom: 1px solid rgba(0, 0, 0, 0.205);
  width: 100%;
  margin: auto;
}

.shippingForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 2vmax;
  justify-content: space-evenly;
  height: 100%;
  transition: all 0.5s;
}

.shippingForm > div {
  display: flex;
  width: 100%;
  align-items: center;
}

.shippingForm > div > input,
.shippingForm > div > select {
  padding: 1vmax 2vmax;
  padding-right: 1vmax;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.267);
  border-radius: 4px;
  font: 300 0.9vmax;
  outline: none;
}

.shippingForm > div > svg {
  position: absolute;
  transform: translateX(1vmax);
  font-size: 1.6vmax;
  color: rgba(0, 0, 0, 0.623);
}

.shippingBtn2 {
  border: none;
  background-color: rgb(12, 131, 31);
  color: white;
  font: 300 1vmax;
  width: 100%;
  padding: 1vmax;
  cursor: pointer;
  transition: all 0.5s;
  outline: none;
  margin: 2vmax;
  border-radius: 10px;
}

@media screen and (max-width: 600px) {
  .shippingBox {
    width: 100vw;
    /* height: 100%; */
    /* margin-bottom: 200px; */
  }

  .shippingHeading {
    font: 400 6vw;
    padding: 5vw;
  }

  .shippingForm {
    padding: 11vw;
    margin-bottom: 200px;
    padding-top: 15vw;
  }

  .shippingForm > div > input,
  .shippingForm > div > select {
    padding: 4vw 10vw;
    font: 300 4vw;
    margin-bottom: 20px;
  }

  .shippingForm > div > svg {
    font-size: 6vw;
    transform: translateX(3vw);
  }

  .shippingBtn2 {
    font: 300 4vw;
    padding: 4vw;
  }
}
